import logger from '../services/logger';

const safely = <T extends unknown[], R>(
  promiseFn: (...args: T) => Promise<R> | R,
  msg?: string,
): ((...args: T) => void) => {
  return (...args: T) => {
    try {
      const result = promiseFn(...args);
      if (result instanceof Promise) {
        result.catch((error) => logger.error(error, msg));
      }
    } catch (error) {
      logger.error(error, msg);
    }
  };
};

export default safely;
