import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import Layout from '../components/layout/layout';
import { useGetUserProfileQuery } from '../services/engage/user-profile/user-profile.api';
import logger from '../services/logger';
import { useAuth } from '../store/auth/auth.slice';
import { routes } from './routes';
import { RouteGroup } from './types';

const RouteGuard = ({ pageComponent }: { pageComponent: ReactNode }) => {
  const router = useRouter();
  const { isAuth } = useAuth();
  const { data: userProfile } = useGetUserProfileQuery(undefined, {
    skip: !isAuth,
  });

  const findCurrentRoute = (routeGroups: RouteGroup[]) => {
    for (const group of routeGroups) {
      if (
        router.pathname === group.mainRoute.path ||
        group.routes.some((subRoute) => router.pathname === subRoute.path)
      ) {
        return {
          ...group.mainRoute,
          ...group.routes.find((subRoute) => router.pathname === subRoute.path),
        };
      }
    }
    return null;
  };

  const currentRoute = findCurrentRoute(routes);
  const isPublicPath = currentRoute?.isPublic;
  const requiredScopes = useMemo(
    () => (currentRoute?.scope ? [currentRoute.scope] : []),
    [currentRoute],
  );

  const userHasScope = useCallback(
    (scope: string) => {
      if (!userProfile) {
        return false;
      }

      return (
        userProfile.roles.includes('admin') ||
        userProfile.scopes.includes(scope)
      );
    },
    [userProfile],
  );

  // Redirect to login page if requesting a private page and not logged in
  useEffect(() => {
    if (!isAuth && !isPublicPath) {
      router.push('/').catch((err: unknown) => logger.error({ err }));
    }
  }, [isAuth, router, isPublicPath]);

  // Redirect to dashboard when attempting to access protected path without scope.
  useEffect(() => {
    if (
      isAuth &&
      requiredScopes.length > 0 &&
      !requiredScopes.every(userHasScope)
    ) {
      router.push('/dashboard').catch((err: unknown) => logger.error({ err }));
    }
  }, [isAuth, router, requiredScopes, userHasScope]);

  // Load requested public page
  if (isPublicPath) {
    return pageComponent;
  }

  return (
    isAuth && (
      <Layout
        drawerwidth={300}
        mainContent={pageComponent}
        routeGroups={routes}
        // FIXME: profileGroups should be imported from somewhere
        profileGroups={[]}
      />
    )
  );
};

export default RouteGuard;
