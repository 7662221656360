import Handlebars from 'handlebars';

import { ChartType, CURRENCY, OPTIONS_NUMBER_FORMATS } from './constants';
import { DataExtractorResult, IChart, IChartDataset } from './types';

const designerCleanCallbackFunction = (text: string) => {
  const startCallbackTagRemoved = text.replace(/"##/g, '');
  const endCallbackTagRemoved = startCallbackTagRemoved.replace(/##"/g, '');
  //const newLineRemoved = endCallbackTagRemoved.replace(/\\n/g, '');
  return endCallbackTagRemoved;
};

const extractFieldCodeFromText = (data: string) => {
  return data.substring(data.indexOf('].') + 2, data.indexOf('}}'));
};

const applyDatasetConditions = (item: IChartDataset) => {
  if (!item.data || !Array.isArray(item.data)) {
    return;
  }

  // Assign Positive/Negative Colors based on values
  if (item.type !== ChartType.PIE && item.type !== ChartType.OUTLABELED) {
    if (item.colorBasedOnValues) {
      const defaultColor = '#000000';
      item.backgroundColor = item.data.map(value =>
        value
          ? value >= 0
            ? (item.backgroundColor1 ?? defaultColor)
            : (item.backgroundColor2 ?? defaultColor)
          : defaultColor,
      );
    } else if (item.backgroundColor1 && item.backgroundColor2) {
      const direction =
        item.type === ChartType.HORIZONTAL_BAR ? 'horizontal' : 'vertical';
      item.backgroundColor = `##getGradientFillHelper('${direction}', ['${item.backgroundColor1}', '${item.backgroundColor2}'])##`;
    } else {
      item.backgroundColor = item.backgroundColor1;
    }
  }

  // Remove when value equals to zero
  if (item.removeWhenValuesZero) {
    item.data = item.data.map(value => (value !== 0 ? value : null));
  }
};

const applyFormatCallback = (
  formatId: string | undefined,
  currencySymbol: string | undefined,
): string | undefined => {
  let callback = undefined;
  if (formatId) {
    if (formatId === OPTIONS_NUMBER_FORMATS.NONE.id) {
      callback = OPTIONS_NUMBER_FORMATS.NONE.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.THOUSAND_SEPARATOR.id) {
      callback = OPTIONS_NUMBER_FORMATS.THOUSAND_SEPARATOR.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.CURRENCY.id) {
      callback = OPTIONS_NUMBER_FORMATS.CURRENCY.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED.id) {
      callback = OPTIONS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.PERCENTAGE.id) {
      callback = OPTIONS_NUMBER_FORMATS.PERCENTAGE.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.CURRENCY_ACCOUNTING.id) {
      callback = OPTIONS_NUMBER_FORMATS.CURRENCY_ACCOUNTING.callback;
    } else if (
      formatId === OPTIONS_NUMBER_FORMATS.CURRENCY_ACCOUNTING_THOUSAND_BASED.id
    ) {
      callback =
        OPTIONS_NUMBER_FORMATS.CURRENCY_ACCOUNTING_THOUSAND_BASED.callback;
    } else if (formatId === OPTIONS_NUMBER_FORMATS.PERCENTAGE_ACCOUNTING.id) {
      callback = OPTIONS_NUMBER_FORMATS.PERCENTAGE_ACCOUNTING.callback;
    }

    if (callback && currencySymbol) {
      callback = callback?.replaceAll(CURRENCY.USD.symbol, currencySymbol);
    }
  }

  return callback;
};

const applyCurrency = (
  dataFromExtractor: DataExtractorResult[],
  value: number,
  numberFormat?: keyof HandlebarCurrencyFunctionProps,
  fieldCurrency?: string,
): string | number | undefined => {
  if (numberFormat && typeof numberFormat === 'string') {
    if (dataFromExtractor.length === 0) {
      return value;
    }

    const currencySymbolData =
      fieldCurrency && dataFromExtractor[0][fieldCurrency];

    const currencySymbol =
      typeof currencySymbolData === 'string'
        ? currencySymbolData
        : fieldCurrency;

    const result = getHandlebarFunctionByCode(numberFormat)?.callback(
      value.toString(),
      currencySymbol,
    ) as unknown; // Safe assertion

    if (typeof result === 'string' || typeof result === 'number') {
      return result;
    }
  }

  return value;
};

const applySum = (
  dataFromExtractor: DataExtractorResult[],
  fieldCode: string,
): number => {
  let value = 0;
  for (const data of dataFromExtractor) {
    const valueFromData = data[fieldCode];
    if (typeof valueFromData === 'number') {
      value += valueFromData;
    }
  }

  return value;
};

export const isHandlebarNumericFunctionByCode = (code: string): boolean => {
  const keyFound =
    Object.keys(HANDLEBARS_NUMERIC_FUNCTIONS).find(
      key =>
        HANDLEBARS_NUMERIC_FUNCTIONS[key as keyof HandlebarNumericFunctionProps]
          .code === code,
    ) ??
    Object.keys(HANDLEBARS_CURRENCY_FUNCTIONS).find(
      key =>
        HANDLEBARS_CURRENCY_FUNCTIONS[
          key as keyof HandlebarCurrencyFunctionProps
        ].code === code,
    );

  return keyFound !== undefined;
};

export const isHandlebarCurrencyFunctionByCode = (code: string): boolean => {
  const keyFound = Object.keys(HANDLEBARS_CURRENCY_FUNCTIONS).find(
    key =>
      HANDLEBARS_CURRENCY_FUNCTIONS[key as keyof HandlebarCurrencyFunctionProps]
        .code === code,
  );
  return keyFound !== undefined;
};

type HandlebarFunctions = HandlebarNumericFunctionProps &
  HandlebarCurrencyFunctionProps &
  HandlebarFunctionProps;

export const getHandlebarFunctionByCode = (code: keyof HandlebarFunctions) => {
  let keyFound = Object.keys(HANDLEBARS_NUMERIC_FUNCTIONS).find(
    key =>
      HANDLEBARS_NUMERIC_FUNCTIONS[key as keyof HandlebarNumericFunctionProps]
        .code === code,
  );
  if (keyFound) {
    return HANDLEBARS_NUMERIC_FUNCTIONS[
      keyFound as keyof HandlebarNumericFunctionProps
    ];
  }

  keyFound = Object.keys(HANDLEBARS_CURRENCY_FUNCTIONS).find(
    key =>
      HANDLEBARS_CURRENCY_FUNCTIONS[key as keyof HandlebarCurrencyFunctionProps]
        .code === code,
  );

  if (keyFound) {
    return HANDLEBARS_CURRENCY_FUNCTIONS[
      keyFound as keyof HandlebarCurrencyFunctionProps
    ];
  }

  keyFound = Object.keys(HANDLEBARS_FUNCTIONS).find(
    key =>
      HANDLEBARS_FUNCTIONS[key as keyof HandlebarFunctionProps].code === code,
  );
  return HANDLEBARS_FUNCTIONS[keyFound as keyof HandlebarFunctionProps];
};

interface HandlebarFunctionItemProps {
  code: string;
  label?: string;
  hint?: string;
  callback: Handlebars.HelperDelegate;
}

interface HandlebarNumericFunctionProps {
  THOUSAND_SEPARATOR: HandlebarFunctionItemProps;
  PERCENTAGE: HandlebarFunctionItemProps;
  PERCENTAGE_ACCOUNTING: HandlebarFunctionItemProps;
}

export const HANDLEBARS_NUMERIC_FUNCTIONS: HandlebarNumericFunctionProps = {
  THOUSAND_SEPARATOR: {
    code: 'thousandSeparator',
    label: 'Thousand Separator',
    hint: '528 -9,470',
    callback: (value: string) => {
      return Number(value).toLocaleString('en');
    },
  },
  PERCENTAGE: {
    code: 'percentage',
    label: 'Percentage',
    hint: '12% -25%',
    callback: (value: string) => {
      return Number(value) / 100 + '%';
    },
  },
  PERCENTAGE_ACCOUNTING: {
    code: 'percentageAccounting',
    label: 'Percentage Accounting',
    hint: '12% (25%)',
    callback: (value: string) => {
      const formattedValue = Number(value) / 100 + '%';

      if (formattedValue.startsWith('-')) {
        return '(' + formattedValue.replace('-', '') + ')';
      } else {
        return formattedValue;
      }
    },
  },
};

interface HandlebarCurrencyFunctionProps {
  CURRENCY: HandlebarFunctionItemProps;
  ACCOUNTING: HandlebarFunctionItemProps;
  THOUSAND_BASED: HandlebarFunctionItemProps;
  ACCOUNTING_THOUSAND_BASED: HandlebarFunctionItemProps;
}

export const HANDLEBARS_CURRENCY_FUNCTIONS: HandlebarCurrencyFunctionProps = {
  CURRENCY: {
    code: 'currency',
    label: 'Currency',
    hint: '$1,385 -$8,724,510',
    callback: (value: string, currency: string = CURRENCY.USD.symbol) => {
      const formattedValue = currency + Number(value).toLocaleString('en');

      //Reordering symbols when negative value is returned
      return formattedValue.startsWith(currency + '-')
        ? formattedValue.replaceAll(currency + '-', '-' + currency)
        : formattedValue;
    },
  },
  ACCOUNTING: {
    code: 'currencyAccounting',
    label: 'Currency Accounting',
    hint: '$1,385 ($8,724,510)',
    callback: (value: string, currency: string = CURRENCY.USD.symbol) => {
      const formattedValue = currency + Number(value).toLocaleString('en');

      //Reordering symbols when negative value is returned
      return formattedValue.startsWith(currency + '-')
        ? formattedValue.replaceAll(currency + '-', '(' + currency) + ')'
        : formattedValue;
    },
  },
  THOUSAND_BASED: {
    code: 'currencyThousandBased',
    label: 'Currency K/M',
    hint: '$12K -$2M',
    callback: (value: string, currency: string = CURRENCY.USD.symbol) => {
      const formattedValue =
        Math.abs(Number(value)) >= 1000000
          ? currency + (Number(value) / 1000000).toFixed(1) + 'M'
          : Math.abs(Number(value)) >= 1000
            ? currency + (Number(value) / 1000).toFixed(1) + 'K'
            : currency + Number(value).toFixed(1);

      //Reordering symbols when negative value is returned
      return formattedValue.startsWith(currency + '-')
        ? formattedValue.replaceAll(currency + '-', '-' + currency)
        : formattedValue;
    },
  },
  ACCOUNTING_THOUSAND_BASED: {
    code: 'currencyThousandBasedAccounting',
    label: 'Currency Accounting K/M',
    hint: '$12K ($2M)',
    callback: (value: string, currency: string = CURRENCY.USD.symbol) => {
      const formattedValue =
        Math.abs(Number(value)) >= 1000000
          ? currency + (Number(value) / 1000000).toFixed(1) + 'M'
          : Math.abs(Number(value)) >= 1000
            ? currency + (Number(value) / 1000).toFixed(1) + 'K'
            : currency + Number(value).toFixed(1);

      //Reordering symbols when negative value is returned
      return formattedValue.startsWith(currency + '-')
        ? formattedValue.replaceAll(currency + '-', '(' + currency) + ')'
        : formattedValue;
    },
  },
};

interface HandlebarFunctionProps {
  IS_EVEN_ODD: HandlebarFunctionItemProps;
  IS_NEGATIVE_NUMBER: HandlebarFunctionItemProps;
  JSON_PARSE: HandlebarFunctionItemProps;
  DEFINE_CHART_CONFIG: HandlebarFunctionItemProps;
  SUM: HandlebarFunctionItemProps;
  AVG: HandlebarFunctionItemProps;
}

export const HANDLEBARS_FUNCTIONS: HandlebarFunctionProps = {
  IS_EVEN_ODD: {
    code: 'isEvenOdd',
    callback: (value: number) => {
      return value % 2 == 0;
    },
  },
  IS_NEGATIVE_NUMBER: {
    code: 'isNegativeNumber',
    callback: (value: string) => {
      try {
        const num = Number(value);

        if (!isNaN(num)) {
          return num < 0;
        } else {
          //When it is a formmatted number/percentage or enclosed in parentheses
          if (
            value.length > 0 &&
            (value.startsWith('-$') ||
              (value.startsWith('(') && value.endsWith(')')) ||
              (value.startsWith('-') && value.endsWith('%')))
          ) {
            return true;
          } else {
            return false;
          }
        }
      } catch (_error) {
        return false;
      }
    },
  },
  JSON_PARSE: {
    code: 'jsonParse',
    callback: (value: string): unknown => {
      try {
        return JSON.parse(value);
      } catch (_error) {
        return value;
      }
    },
  },
  DEFINE_CHART_CONFIG: {
    code: 'defineChartConfig',
    callback: (
      dataFromExtractor: DataExtractorResult[] | undefined,
      config: string,
      imageAssetsUrl: string,
    ) => {
      try {
        if (!dataFromExtractor) {
          return imageAssetsUrl + '/designer/chart-no-data.png';
        }

        // FIXME: This should be validated as an IChart object.
        // An assertion is only realistic if the data has already been fully validated prior to this point
        // and there is a guarantee that the data could not have been modified.
        // Valibot could be used to validate the data before it is passed to this function.
        const chartConfig = JSON.parse(
          decodeURIComponent(config).replace(/\\'/g, "'"),
        ) as IChart;
        let containsDynamicExpressions = false;

        if (
          chartConfig.data.labels &&
          typeof chartConfig.data.labels === 'string' &&
          chartConfig.data.labels.startsWith('{')
        ) {
          const labelField = extractFieldCodeFromText(chartConfig.data.labels);

          // Support current charts where the label is concatenated in a single Field
          const labelFieldData =
            dataFromExtractor.length > 0
              ? dataFromExtractor[0][labelField]
              : undefined;
          if (
            labelFieldData &&
            typeof labelFieldData === 'string' &&
            labelFieldData.startsWith('[')
          ) {
            containsDynamicExpressions = true;

            try {
              const labels: unknown = JSON.parse(
                labelFieldData.replace(/'/g, '"'),
              );
              if (Array.isArray(labels)) {
                chartConfig.data.labels = labels;
              }
            } catch (_error) {
              chartConfig.data.labels = ['Not able to get the labels'];
            }
          } else {
            // Get array of labels
            chartConfig.data.labels = dataFromExtractor.map(data => {
              const label = data[labelField];
              return label && typeof label === 'string'
                ? label
                : typeof label === 'number'
                  ? label.toString()
                  : '';
            });
          }
        }

        if (chartConfig.data.datasets && chartConfig.data.datasets.length) {
          chartConfig.data.datasets?.map((item, _index) => {
            if (item.label && item.label.startsWith('{')) {
              containsDynamicExpressions = true;
              const dataLabelField = extractFieldCodeFromText(item.label);

              item.label =
                dataFromExtractor.length > 0
                  ? (dataFromExtractor[0][dataLabelField] as string)
                  : '';
            }

            if (
              item.data &&
              typeof item.data === 'string' &&
              item.data.startsWith('{')
            ) {
              const dataField = extractFieldCodeFromText(item.data);

              containsDynamicExpressions = true;

              // Support current charts where the label is concatenated in a single Field
              const dataFieldData =
                dataFromExtractor.length > 0
                  ? dataFromExtractor[0][dataField]
                  : undefined;
              if (
                dataFieldData &&
                typeof dataFieldData === 'string' &&
                dataFieldData.startsWith('[')
              ) {
                containsDynamicExpressions = true;

                // Convert the string to array
                try {
                  const values: unknown = JSON.parse(
                    dataFieldData.replace(/'/g, '"'),
                  );
                  if (Array.isArray(values)) {
                    item.data = values;
                  }
                } catch (_error) {
                  item.data = [0];
                  item.label = 'Not able to get the values';
                }
              } else {
                // Get array of values
                item.data = dataFromExtractor.map(data => {
                  const value = data[dataField];
                  return typeof value === 'number' ? value : 0;
                });
              }
            }

            applyDatasetConditions(item);
          });
        }

        if (
          dataFromExtractor.length === 0 &&
          //contains dynamic expressions
          containsDynamicExpressions
        ) {
          // 'showMissingData' or 'hideChart'
          // TODO: Link to public URLs
          return chartConfig.behaviorWhenNoData === 'showMissingData'
            ? imageAssetsUrl + '/designer/chart-no-data.png'
            : imageAssetsUrl + '/designer/empty-chart-1_1000.png';
        }

        // Applying options config
        if (chartConfig.options) {
          let currencySymbolScales = chartConfig.options.scales?.currencySymbol;
          if (currencySymbolScales?.startsWith('{')) {
            const currencyField =
              extractFieldCodeFromText(currencySymbolScales);
            const currencyFieldData =
              dataFromExtractor.length > 0
                ? dataFromExtractor[0][currencyField]
                : undefined;
            currencySymbolScales =
              currencyFieldData && typeof currencyFieldData === 'string'
                ? currencyFieldData
                : '';
          }

          let currencySymbolPlugins =
            chartConfig.options.plugins.currencySymbol;
          if (currencySymbolPlugins?.startsWith('{')) {
            const currencyField = extractFieldCodeFromText(
              currencySymbolPlugins,
            );
            const currencyFieldData =
              dataFromExtractor.length > 0
                ? dataFromExtractor[0][currencyField]
                : undefined;
            currencySymbolPlugins =
              currencyFieldData && typeof currencyFieldData === 'string'
                ? currencyFieldData
                : '';
          }

          if (
            chartConfig.type === ChartType.OUTLABELED &&
            chartConfig.options.plugins.outlabels.numberFormatId
          ) {
            // Outlabeled Pie
            chartConfig.options.plugins.outlabels.text = applyFormatCallback(
              chartConfig.options.plugins.outlabels.numberFormatId,
              currencySymbolPlugins,
            );
          } else if (
            chartConfig.type === ChartType.PIE &&
            chartConfig.options.plugins.datalabels.numberFormatId
          ) {
            // Pie Chart
            chartConfig.options.plugins.datalabels.formatter =
              applyFormatCallback(
                chartConfig.options.plugins.datalabels.numberFormatId,
                currencySymbolPlugins,
              );
          } else if (chartConfig.type === ChartType.RADIAL_GAUGE) {
            // Radia Gauge Chart
            chartConfig.options.centerArea = {
              text: OPTIONS_NUMBER_FORMATS.PERCENTAGE_NO_DIVIDED.callback,
            };
          } else {
            // Line, Bar
            let formatXAxis;
            let formatYAxis;
            if (chartConfig.options.scales?.yAxes[0].ticks?.numberFormatId) {
              formatYAxis = applyFormatCallback(
                chartConfig.options.scales.yAxes[0].ticks.numberFormatId,
                currencySymbolScales,
              );
              chartConfig.options.scales.yAxes[0].ticks.callback = formatYAxis;
            }

            if (chartConfig.options.scales?.xAxes[0].ticks?.numberFormatId) {
              formatXAxis = applyFormatCallback(
                chartConfig.options.scales.xAxes[0].ticks.numberFormatId,
                currencySymbolScales,
              );
              chartConfig.options.scales.xAxes[0].ticks.callback = formatXAxis;
            }

            if (formatXAxis || formatYAxis) {
              if (chartConfig.type === ChartType.HORIZONTAL_BAR) {
                chartConfig.options.plugins.datalabels.formatter = formatXAxis;
              } else {
                chartConfig.options.plugins.datalabels.formatter = formatYAxis;
              }
            }
          }

          // To allow new line in outlabels format
          if (chartConfig.options.plugins.outlabels.text) {
            chartConfig.options.plugins.outlabels.text =
              chartConfig.options.plugins.outlabels.text.replaceAll(
                '%nl',
                '\n',
              );
          }
        }

        let layout = chartConfig.layout?.width
          ? `w=${chartConfig.layout?.width}&`
          : '';
        layout += chartConfig.layout?.height
          ? `h=${chartConfig.layout?.height}&`
          : '';
        layout += chartConfig.layout?.backgroundColor
          ? `bkg=${encodeURIComponent(chartConfig.layout?.backgroundColor)}&`
          : '';

        return `https://quickchart.io/chart?${layout}c=${encodeURIComponent(designerCleanCallbackFunction(JSON.stringify(chartConfig)))}`;
      } catch (_error) {
        return imageAssetsUrl + '/designer/chart-no-data.png';
      }
    },
  },
  SUM: {
    code: 'sum',
    label: 'Sum Values',
    callback: (
      dataFromExtractor: DataExtractorResult[],
      fieldCode: string,
      numberFormat?: keyof HandlebarCurrencyFunctionProps,
      fieldCurrency?: string,
      colorPositive?: string,
      colorNegative?: string,
    ) => {
      if (!dataFromExtractor) {
        return;
      }

      const value = applySum(dataFromExtractor, fieldCode);

      const footerColor =
        value > 0
          ? (colorPositive ?? 'inherit')
          : (colorNegative ?? colorPositive ?? 'inherit');

      return `<span style="color:${footerColor}">${applyCurrency(
        dataFromExtractor,
        value,
        numberFormat,
        fieldCurrency,
      )}</span>`;
    },
  },
  AVG: {
    code: 'avg',
    label: 'Average Values',
    callback: (
      dataFromExtractor: DataExtractorResult[],
      fieldCode: string,
      numberFormat: keyof HandlebarCurrencyFunctionProps,
      fieldCurrency: string,
      colorPositive?: string,
      colorNegative?: string,
    ) => {
      if (!dataFromExtractor) {
        return;
      }

      const value =
        applySum(dataFromExtractor, fieldCode) / dataFromExtractor.length;

      const footerColor =
        value > 0
          ? (colorPositive ?? 'inherit')
          : (colorNegative ?? colorPositive ?? 'inherit');

      return `<span style="color:${footerColor}">${applyCurrency(
        dataFromExtractor,
        value,
        numberFormat,
        fieldCurrency,
      )}</span>`;
    },
  },
};

export const setupHandleBarsFunctions = () => {
  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.IS_NEGATIVE_NUMBER.code,
    HANDLEBARS_FUNCTIONS.IS_NEGATIVE_NUMBER.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.IS_EVEN_ODD.code,
    HANDLEBARS_FUNCTIONS.IS_EVEN_ODD.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.JSON_PARSE.code,
    HANDLEBARS_FUNCTIONS.JSON_PARSE.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.DEFINE_CHART_CONFIG.code,
    HANDLEBARS_FUNCTIONS.DEFINE_CHART_CONFIG.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_NUMERIC_FUNCTIONS.THOUSAND_SEPARATOR.code,
    HANDLEBARS_NUMERIC_FUNCTIONS.THOUSAND_SEPARATOR.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_NUMERIC_FUNCTIONS.PERCENTAGE.code,
    HANDLEBARS_NUMERIC_FUNCTIONS.PERCENTAGE.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_NUMERIC_FUNCTIONS.PERCENTAGE_ACCOUNTING.code,
    HANDLEBARS_NUMERIC_FUNCTIONS.PERCENTAGE_ACCOUNTING.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_CURRENCY_FUNCTIONS.CURRENCY.code,
    HANDLEBARS_CURRENCY_FUNCTIONS.CURRENCY.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_CURRENCY_FUNCTIONS.ACCOUNTING.code,
    HANDLEBARS_CURRENCY_FUNCTIONS.ACCOUNTING.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_CURRENCY_FUNCTIONS.THOUSAND_BASED.code,
    HANDLEBARS_CURRENCY_FUNCTIONS.THOUSAND_BASED.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_CURRENCY_FUNCTIONS.ACCOUNTING_THOUSAND_BASED.code,
    HANDLEBARS_CURRENCY_FUNCTIONS.ACCOUNTING_THOUSAND_BASED.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.SUM.code,
    HANDLEBARS_FUNCTIONS.SUM.callback,
  );

  Handlebars.registerHelper(
    HANDLEBARS_FUNCTIONS.AVG.code,
    HANDLEBARS_FUNCTIONS.AVG.callback,
  );
};
