import {
  JobSchedule,
  ScheduleIncrementObject,
  ScheduleValue,
} from '@rego-engage/engage-types';
import Joi from 'joi';
import parsePhoneNumberBase, {
  isValidNumber,
  PhoneNumber,
} from 'libphonenumber-js';

export const parsePhoneNumber = (
  phoneNumber: string,
): PhoneNumber | undefined => {
  if (isValidNumber(phoneNumber)) {
    return parsePhoneNumberBase(phoneNumber);
  }
  return undefined;
};

interface NestedObject<T> {
  [key: string]: T | NestedObject<T>;
}

const saveValueAtObjectPath = <T>(
  k: NestedObject<T>,
  path: string[],
  v: T,
): NestedObject<T> => {
  const newObj: NestedObject<T> = { ...k };

  let currentObj: NestedObject<T> = newObj;
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (typeof currentObj[key] !== 'object' || currentObj[key] === null) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key] as NestedObject<T>;
  }

  currentObj[path[path.length - 1]] = v;

  return newObj;
};

export const validateForm = <T extends object>(
  fields: Partial<T>,
  schema: Joi.ObjectSchema<T>,
): { result: Partial<T>; error?: Partial<T> } => {
  const iFields = { ...fields };
  const validator = schema;
  const validatorResponse = validator
    .options({ stripUnknown: true })
    .validate(iFields);
  const { error } = validatorResponse;
  if (error) {
    const { details } = error;
    const errors: Record<string, T> = details.reduce((prev, curr) => {
      if (curr.context?.key) {
        const pathKeys = curr.path as string[];
        const nestedObject = saveValueAtObjectPath(
          prev,
          pathKeys,
          curr.message,
        );
        return { ...prev, ...nestedObject };
      }
      return prev;
    }, {});
    return { result: {}, error: errors as Partial<T> };
  } else {
    return { result: validatorResponse.value };
  }
};

export const validatePhone = (phoneNumber: string) => {
  const res = parsePhoneNumber(phoneNumber);
  if (!res?.isValid()) {
    return false;
  }
  return true;
};

export const cleanPhone = (phoneNumber: string) => {
  const cleanedPhone = phoneNumber.replace(/[^0-9]/g, '');
  return `+${cleanedPhone}`;
};

export class JobScheduleValidator {
  fieldRanges: Record<string, { min: number; max: number }> = {
    minutes: { min: 0, max: 59 },
    hours: { min: 0, max: 23 },
    dayOfMonth: { min: 1, max: 31 },
    month: { min: 1, max: 12 },
    dayOfWeek: { min: 1, max: 7 },
    year: { min: 1970, max: 2199 },
  };

  private validateRange(
    values: number[],
    min: number,
    max: number,
    field: string,
  ): void {
    if (values.length > 0) {
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);

      if (minValue < min || maxValue > max) {
        throw new Error(
          `Invalid values in ${field}. All values should be between ${min} and ${max}.`,
        );
      }
    }
  }

  public isScheduleValuePresent(value?: ScheduleValue): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'object') {
      return true;
    }
    return false;
  }

  public isScheduleIncrementObject(
    value?: Partial<ScheduleValue>,
  ): value is ScheduleIncrementObject {
    return (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      'start' in value &&
      'increment' in value
    );
  }

  private getScheduleValueLength(data?: Partial<ScheduleValue>): number {
    if (Array.isArray(data)) {
      return data.length;
    } else if (typeof data === 'object') {
      return 1; // Increment object represents a range, so consider its length as 1
    }
    return 0;
  }

  public validateJobSchedule(schedule: Partial<JobSchedule>): void {
    Object.keys(this.fieldRanges).forEach((field) => {
      const { min: minValue, max: maxValue } = this.fieldRanges[field];

      const fieldData = schedule[field as keyof JobSchedule];
      if (Array.isArray(fieldData)) {
        this.validateRange(fieldData, minValue, maxValue, field);
      } else if (typeof fieldData === 'object') {
        this.validateRange(
          [fieldData.start as number],
          minValue,
          maxValue,
          `${field}.start`,
        );
        if (fieldData.increment <= 0) {
          throw new Error(
            `Invalid increment in ${field}. Increment should be greater than 0.`,
          );
        }
      }
    });

    // dayOfMonth + dayOfWeek are invalid
    if (
      this.getScheduleValueLength(schedule.dayOfMonth) > 0 &&
      this.getScheduleValueLength(schedule.dayOfWeek) > 0
    ) {
      throw new Error(
        'You cannot specify both Day-of-month and Day-of-week fields in the same cron expression. Use an empty array in one of them.',
      );
    }

    // dayOfWeekInstance + dayOfMonth are invalid
    if (
      schedule.dayOfWeekInstance !== undefined &&
      this.getScheduleValueLength(schedule.dayOfMonth) > 0
    ) {
      throw new Error(
        'You cannot specify both Day-of-month and Day-of-week instance in the same cron expression.',
      );
    }

    // nearestWeekday + (dayOfWeek || dayOfWeekInstance) is invalid
    if (
      schedule.nearestWeekday &&
      (this.getScheduleValueLength(schedule.dayOfWeek) > 0 ||
        schedule.dayOfWeekInstance !== undefined)
    ) {
      throw new Error(
        'You cannot specify Nearest weekday and Day-of-week or Day-of-week instance in the same cron expression.',
      );
    }

    // nearestWeekday + dayOfMonth multi-value array is invalid
    if (
      schedule.nearestWeekday &&
      !schedule.lastDayOfMonth &&
      this.getScheduleValueLength(schedule.dayOfMonth) !== 1
    ) {
      throw new Error(
        'When using Nearest weekday (W) wildcard, you can only specify a single day in the Day-of-month field. No ranges are allowed.',
      );
    }

    // dayOfWeekInstance cannot be used with increment objects
    if (
      schedule.dayOfWeekInstance &&
      this.isScheduleIncrementObject(schedule.dayOfWeek)
    ) {
      throw new Error(
        'When using Day-of-week instance, you cannot use an increment value for Day-of-week',
      );
    }

    // dayOfWeekInstance without dayOfWeek is invalid
    if (
      schedule.dayOfWeekInstance &&
      this.getScheduleValueLength(schedule.dayOfWeek) !== 1
    ) {
      throw new Error(
        'When using Day-of-week instance, you must specify a single Day-of-week value',
      );
    }

    // If lastDayOfMonth is true, then dayOfMonth, dayOfWeek, and dayOfWeekInstance should not be set.
    if (
      schedule.lastDayOfMonth &&
      (this.getScheduleValueLength(schedule.dayOfMonth) > 0 ||
        this.getScheduleValueLength(schedule.dayOfWeek) > 0 ||
        schedule.dayOfWeekInstance !== undefined)
    ) {
      throw new Error(
        'When using Last day of month (L) wildcard, you cannot specify Day-of-month, Day-of-week, or Day-of-week instance',
      );
    }

    // nearestWeekday without lastDayOfMonth or dayOfMonth is invalid
    if (
      schedule.nearestWeekday &&
      !schedule.lastDayOfMonth &&
      this.getScheduleValueLength(schedule.dayOfMonth) === 0
    ) {
      throw new Error(
        'When using Nearest weekday (W) wildcard, you must specify either Last day of month (L) or a single day in the Day-of-month field.',
      );
    }

    // lastInstanceOfDayOfWeek without dayOfWeek is invalid
    if (
      schedule.lastInstanceOfDayOfWeek &&
      this.getScheduleValueLength(schedule.dayOfWeek) !== 1
    ) {
      throw new Error(
        'When using Last instance of Day-of-week (L) wildcard, you must specify a single Day-of-week value',
      );
    }

    // lastInstanceOfDayOfWeek + (dayOfMonth || dayOfWeekInstance || nearestWeekday || lastDayOfMonth) is invalid
    if (
      schedule.lastInstanceOfDayOfWeek &&
      (this.isScheduleValuePresent(schedule.dayOfMonth) ||
        schedule.dayOfWeekInstance !== undefined ||
        schedule.nearestWeekday ||
        schedule.lastDayOfMonth)
    ) {
      throw new Error(
        'When using Last instance of Day-of-week (L) wildcard, you cannot specify Day-of-month, Day-of-week instance, Nearest weekday, or Last day of month.',
      );
    }
    // When using an increment value for Month, you must specify either Day-of-month or Day-of-week
    if (
      this.isScheduleIncrementObject(schedule.month) &&
      this.getScheduleValueLength(schedule.dayOfMonth) === 0 &&
      this.getScheduleValueLength(schedule.dayOfWeek) === 0
    ) {
      throw new Error(
        'When using an increment value for Month, you must specify either Day-of-month or Day-of-week',
      );
    }
  }

  private convertToWildcardFormat(field: number[]): string {
    const sortedField = field.slice().sort((a, b) => a - b);
    const ranges: number[][] = [];
    let currentRange: number[] = [];

    sortedField.forEach((value, index) => {
      if (index === 0 || (index > 0 && sortedField[index - 1] + 1 === value)) {
        currentRange.push(value);
      } else {
        ranges.push(currentRange);
        currentRange = [value];
      }

      if (index === sortedField.length - 1) {
        ranges.push(currentRange);
      }
    });

    return ranges
      .map((range) =>
        range.length > 1
          ? `${range[0]}-${range[range.length - 1]}`
          : `${range[0]}`,
      )
      .join(',');
  }

  private dayOfWeekInstanceToWildcard(
    dayOfWeek: number,
    instance: number,
  ): string {
    return `${dayOfWeek}#${instance}`;
  }

  private handleSimpleScheduleField(
    fieldValue: ScheduleValue,
    defaultValue: string,
  ) {
    if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0
        ? this.convertToWildcardFormat(fieldValue)
        : defaultValue;
    } else if (this.isScheduleIncrementObject(fieldValue)) {
      return `${fieldValue.start}/${fieldValue.increment}`;
    }
    return defaultValue;
  }

  private handleDayOfMonthField(schedule: JobSchedule): string {
    if (schedule.lastDayOfMonth) {
      return schedule.nearestWeekday ? 'LW' : 'L';
    } else if (this.isScheduleIncrementObject(schedule.dayOfMonth)) {
      return `${schedule.dayOfMonth.start}/${schedule.dayOfMonth.increment}`;
    } else if (
      Array.isArray(schedule.dayOfMonth) &&
      schedule.dayOfMonth.length > 0
    ) {
      return schedule.nearestWeekday
        ? this.convertToWildcardFormat(schedule.dayOfMonth) + 'W'
        : this.convertToWildcardFormat(schedule.dayOfMonth);
    }
    return '?';
  }

  private handleDayOfWeekField(schedule: JobSchedule): string {
    if (schedule.lastInstanceOfDayOfWeek) {
      return `${schedule.dayOfWeek[0]}L`;
    } else if (schedule.dayOfWeekInstance) {
      return this.dayOfWeekInstanceToWildcard(
        schedule.dayOfWeek[0],
        schedule.dayOfWeekInstance,
      );
    } else if (this.isScheduleIncrementObject(schedule.dayOfWeek)) {
      return `${schedule.dayOfWeek.start}/${schedule.dayOfWeek.increment}`;
    } else if (
      Array.isArray(schedule.dayOfWeek) &&
      schedule.dayOfWeek.length > 0
    ) {
      return this.convertToWildcardFormat(schedule.dayOfWeek);
    } else if (
      Array.isArray(schedule.dayOfMonth) &&
      schedule.dayOfMonth.length === 0 &&
      !schedule.lastDayOfMonth
    ) {
      return '*';
    }
    return '?';
  }

  public scheduleToCronExpression(schedule: JobSchedule): string {
    this.validateJobSchedule(schedule);

    const cronExpression: string = [
      this.handleSimpleScheduleField(schedule.minutes, '*'),
      this.handleSimpleScheduleField(schedule.hours, '*'),
      this.handleDayOfMonthField(schedule),
      this.handleSimpleScheduleField(schedule.month, '*'),
      this.handleDayOfWeekField(schedule),
      this.handleSimpleScheduleField(schedule.year, '*'),
    ].join(' ');

    return cronExpression;
  }
}
