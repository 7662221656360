import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';

import logger from '../../services/logger';
import { NotificationOrder } from './types';

export const ToastProvider = () => {
  return <ToastContainer />;
};

const NOTIFICATION_TIMEOUT = 3000; // 3 seconds
const ERROR_TIMEOUT = 60 * 1000; // 1 minute

const customToast = (request: NotificationOrder) => {
  logger.debug({ request }, 'customToast');
  toast[request.type](request.message, {
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    closeOnClick: request.closeOnClick,
    autoClose: request.type === 'error' ? ERROR_TIMEOUT : NOTIFICATION_TIMEOUT,
    position: 'bottom-right',
    closeButton: true,
    hideProgressBar: true,
    onClick: request.onClick,
    theme: 'colored',
  });
};

export const useNotification = () => customToast;
