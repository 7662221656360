import logger from '../services/logger';

export type Pathname = string | null | (string | null | undefined)[];

export type Search =
  | string
  | null
  | Record<string, string | number | boolean | null | undefined>;

/**
 * Options for building a URL.
 */
export interface UrlOptions {
  /**
   * The full URL to parse and build from.
   */
  url?: string;

  /**
   * The base URL to resolve against, if `url` is relative.
   */
  base?: string;

  /**
   * The protocol to use (e.g., 'http', 'https').
   */
  protocol?: string;

  /**
   * The username for authentication.
   */
  username?: string;

  /**
   * The password for authentication.
   */
  password?: string;

  /**
   * The hostname (e.g., 'example.com').
   */
  hostname?: string;

  /**
   * The host, including hostname and port.
   */
  host?: string;

  /**
   * The port number to connect to.
   */
  port?: string;

  /**
   * The pathname or path segments to use.
   */
  pathname?: Pathname;

  /**
   * Path segments to append to the existing path. This allows for extending the
   * base path defined in `pathname` with additional segments. If the appended
   * path includes query parameters, they will be merged with existing ones.
   *
   * ```ts
   * // Example 1: Appending a simple path segment
   * appendPath: '/subresource/123'  // Results in "/api/v1/resource/subresource/123"
   *
   * // Example 2: Appending multiple segments
   * appendPath: ['subresource', '456']  // Results in "/api/v1/resource/subresource/456"
   *
   * // Example 3: Appending path with query parameters
   * search: { param1: 'value1' }
   * appendPath: '/subresource/123?newParam=newValue'  // Merges with existing query parameters
   * // Results in "/api/v1/resource/subresource/123?param1=value1&newParam=newValue"
   * ```
   */
  appendPath?: Pathname;

  /**
   * The search parameters or query string to use.
   */
  search?: Search;

  /**
   * The hash fragment to use (e.g., '#section1').
   */
  hash?: string;
}

interface PopulateSearchParamsOptions {
  params: URLSearchParams;
  search?: Search;
}

/**
 * Populate the given search params with the given search.
 * @param params The search params to populate.
 * @param search The search to populate the search params with.
 * @returns The populated search params.
 */
export const populateSearchParams = ({
  params,
  search,
}: PopulateSearchParamsOptions): URLSearchParams => {
  if (search) {
    if (typeof search === 'string') {
      const tmpParams = new URLSearchParams(search);
      tmpParams.forEach((value, key) => params.set(key, value));
    } else if (typeof search === 'object') {
      Object.entries(search).forEach(([key, value]) => {
        if (value != undefined) {
          params.set(key, value.toString());
        }
      });
    }
  }

  return params;
};

/**
 * Build a URL from the given options.
 * @param options The options to build the URL from.
 * @returns The URL.
 */
export const buildPath = (pathname: Pathname): string => {
  const pathSegments =
    typeof pathname === 'string' ? [pathname] : pathname ?? [];

  return pathSegments
    .filter((segment) => segment != null)
    .join('/')
    .replace(/\/+/g, '/');
};

/**
 * Build a query string from the given query.
 * @param search The search to build the query string from.
 * @returns The query string.
 */
export const bq = (search: Search = ''): string => {
  try {
    const params = new URLSearchParams(
      typeof search === 'string' ? search : undefined,
    );

    if (typeof search === 'object') {
      populateSearchParams({ params, search });
    }

    const query = params.toString();
    return query ? `?${query}` : '';
  } catch (err) {
    logger.error(err);
    return '';
  }
};

type BPathOptions = Pick<UrlOptions, 'pathname' | 'search' | 'hash'>;

/**
 * Build a path from the given options.
 * @param options The options to build the path from.
 * @returns The path.
 */
export const bpath = (options: BPathOptions): string => {
  const path = options.pathname ? buildPath(options.pathname) : '';

  const search = options.search ? bq(options.search) : '';

  const hash = options.hash ? `#${options.hash.replace(/^#*/, '')}` : '';

  return `${path}${search}${hash}`;
};

/**
 * Build a URL from the given options.
 * @param options The options to build the URL from.
 * @returns The URL.
 */
export const burl = (options: UrlOptions): string => {
  try {
    const url = new URL(options.url ?? '', options.base);

    if (options.protocol) {
      url.protocol = options.protocol;
    }

    if (options.username) {
      url.username = options.username;
    }

    if (options.password) {
      url.password = options.password;
    }

    if (options.hostname) {
      url.hostname = options.hostname;
    }

    if (options.host) {
      url.host = options.host;
    }

    if (options.port) {
      url.port = options.port;
    }

    if (options.pathname) {
      url.pathname = buildPath(options.pathname);
    }

    if (options.appendPath) {
      let [path, query] = ['', ''];
      if (typeof options.appendPath === 'string') {
        [path, query] = options.appendPath.split('?');
      } else if (Array.isArray(options.appendPath)) {
        const fullPath = buildPath(options.appendPath);
        [path, query] = fullPath.split('?');
      }

      const pathToAppend = buildPath(path);

      // Prepend the current path and append the new path, handling slashes
      url.pathname = [
        url.pathname.replace(/\/$/, ''),
        pathToAppend.replace(/^\//, ''),
      ].join('/');

      // Append the query
      if (query) {
        const params = new URLSearchParams(query);
        params.forEach((value, key) => url.searchParams.set(key, value));
      }
    }

    if (options.search) {
      if (typeof options.search === 'string') {
        url.search = options.search;
      } else {
        populateSearchParams({
          params: url.searchParams,
          search: options.search,
        });
      }
    }

    if (options.hash) {
      url.hash = options.hash;
    }

    const result = url.toString();

    return result ? result : '';
  } catch (err) {
    logger.error(err);
    return '';
  }
};
