import Button from '@mui/joy/Button';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import { X } from '@untitled-ui/icons-react';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useListCompaniesQuery } from '../../services/engage/company/company.api';
import {
  useGetCompanyId,
  useMutateCompanyId,
} from '../../store/app-state/app-state.slice';

const CompanySelector = () => {
  const router = useRouter();
  const [selected, setSelected] = React.useState<boolean>(false);
  const selectedCompanyId = useGetCompanyId();
  const mutateCompany = useMutateCompanyId();

  const { data: companies, isLoading: companiesIsLoading } =
    useListCompaniesQuery();

  // update selected to true if companyId is present in the url
  useEffect(() => {
    if (router.query.companyId) {
      setSelected(true);
    }
  }, [router.query.companyId]);

  const handleSelectCompany = (
    _event: React.SyntheticEvent | null,
    newValue: string | null,
  ) => {
    setSelected(true);
    const newCompanyId = newValue;
    const newQuery = { ...router.query };

    if (newCompanyId) {
      newQuery.companyId = newCompanyId;
    } else {
      delete newQuery.companyId;
    }

    const newUrl = {
      pathname: router.pathname,
      query: newQuery,
    };

    void router.push(newUrl).then(() => {
      mutateCompany(newCompanyId ?? '');
    });
  };

  const handleClearCompany = () => {
    handleSelectCompany(null, '');
    setSelected(false);
  };

  if (companiesIsLoading) {
    return null;
  }

  return (
    <>
      <div className="flex gap-2">
        <Select
          size="sm"
          className="flex-auto"
          value={selectedCompanyId}
          onChange={handleSelectCompany}
        >
          <Option key="none" value="">
            No Company Selected
          </Option>
          {companies?.map((company) => (
            <Option key={company.companyId} value={company.companyId}>
              {company.name}
            </Option>
          ))}
        </Select>
        {selected && (
          <Button
            size="sm"
            variant="outlined"
            color="neutral"
            onClick={handleClearCompany}
            style={{ padding: '0 0.25rem' }}
          >
            <X />
          </Button>
        )}
      </div>
    </>
  );
};

export default CompanySelector;
