/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type Branding = Static<typeof Branding>;
export const Branding = Type.Object({
  brandId: Type.String(),
  companyId: Type.String(),
  primaryColor: Type.Union([Type.String(), Type.Null()]),
  secondaryColor: Type.Union([Type.String(), Type.Null()]),
  tertiaryColor: Type.Union([Type.String(), Type.Null()]),
  senderName: Type.Union([Type.String(), Type.Null()]),
  senderEmail: Type.Union([Type.String(), Type.Null()]),
  logoUrl: Type.Union([Type.String(), Type.Null()]),
});

export type BrandingUpdatePayload = Static<typeof BrandingUpdatePayload>;
export const BrandingUpdatePayload = Type.Intersect([
  Type.Partial(
    Type.Pick(
      Branding,
      Type.Union([
        Type.Literal('primaryColor'),
        Type.Literal('secondaryColor'),
        Type.Literal('tertiaryColor'),
        Type.Literal('senderEmail'),
        Type.Literal('senderName'),
      ])
    )
  ),
  Type.Object({
    logoUrl: Type.Optional(Type.Null()),
  }),
]);
