/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { ScheduleIncrementObject } from './schedule-increment-object.schema';

export type ScheduleValue = Static<typeof ScheduleValue>;
export const ScheduleValue = Type.Union(
  [Type.Array(Type.Number()), ScheduleIncrementObject],
  { default: [] }
);
