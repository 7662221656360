import { FormatRegistry } from '@sinclair/typebox';
import { TypeSystem } from '@sinclair/typebox/system';

import { validatePhone } from '../../helpers/custom-validators';
import {
  isValidDateFormat,
  isValidDateTimeFormat,
  isValidTimeFormat,
} from '../formats/date';
import {
  isValidTimezoneFormat,
  isValidTzOffsetFormat,
} from '../formats/timezone';

export const StartTypeEngine = () => {
  !FormatRegistry.Has('notEmpty') &&
    TypeSystem.Format('notEmpty', (value) => value.trim().length > 0);

  !FormatRegistry.Has('UUIDv4') &&
    TypeSystem.Format('UUIDv4', (value) =>
      /^(?:urn:uuid:)?[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i.test(
        value,
      ),
    );

  !FormatRegistry.Has('date') &&
    TypeSystem.Format('date', (value) => isValidDateFormat(value));

  !FormatRegistry.Has('date-time') &&
    TypeSystem.Format('date-time', (value) => isValidDateTimeFormat(value));

  !FormatRegistry.Has('time') &&
    TypeSystem.Format('time', (value) => isValidTimeFormat(value));

  !FormatRegistry.Has('timezone') &&
    TypeSystem.Format('timezone', (value) => isValidTimezoneFormat(value));

  !FormatRegistry.Has('tz-offset') &&
    TypeSystem.Format('tz-offset', (value) => isValidTzOffsetFormat(value));

  !FormatRegistry.Has('email') &&
    TypeSystem.Format('email', (value) =>
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
        value,
      ),
    );

  !FormatRegistry.Has('password') &&
    TypeSystem.Format('password', (value) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value),
    );

  !FormatRegistry.Has('phone') &&
    TypeSystem.Format('phone', (value) => validatePhone(value));
};
