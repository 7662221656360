/* eslint-disable @typescript-eslint/no-redeclare */
import {
  Static,
  TLiteral,
  TRecord,
  TString,
  TUnknown,
  Type,
} from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';

export type BlockData<
  Attr extends TRecord<TString, TString | TLiteral> = TRecord<TString, TString>,
  Data extends TRecord<TString> = TRecord<TString, TUnknown>,
> = Static<ReturnType<typeof BlockData<Attr, Data>>>;
export const BlockData = <
  Attr extends TRecord<TString, TString | TLiteral> = TRecord<TString, TString>,
  Data extends TRecord<TString> = TRecord<TString, TUnknown>,
>(
  Attr: Attr = Type.Record(Type.String(), Type.String()) as Attr,
  Data: Data = Type.Record(Type.String(), Type.Unknown()) as Data,
) =>
  Type.Recursive((This) =>
    Type.Object({
      title: Type.Optional(Type.String()),
      type: Type.String(),
      data: Type.Object({
        value: Data,
        hidden: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
      }),
      attributes: Type.Intersect([
        Attr,
        Type.Object({
          'css-class': Type.Optional(Type.String()),
        }),
      ]),
      children: Type.Array(This),
    }),
  );

export const BlockDataValidator = TypeCompiler.Compile(BlockData());
